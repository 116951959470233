import React from 'react'
import {
  SideNav,
  Grid,
  Heading,
  TargetLogo,
  Anchor,
} from '@enterprise-ui/canvas-ui-react'
import * as GlobalConstants from '../HeaderConstants'
import TestUserLogin from '../util/TestUserLogin'
import AccessControl from '../security/AccessControl'
import './LeftNavigation.css'

class LeftNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.EDI_REGISTRATION_USER = 'Partner Registration'
    this.EDI_REGISTRATION_INTERNAL_USER = 'Super User Registration'
    this.EDI_REGISTRATION_STATUS = 'Partner Registration Status'
    this.EDI_PARTNER_INFO = 'EDI Partner Info'
    this.EDI_DATA_VIEW = 'EDI Data View'
    this.EDI_DATA_VIEW_MFE = 'EDI Data View - New'
    this.EDI_TEST_DATA_GENERATION = 'EDI Test Data Generation'
    this.EDI_REPORTS = 'EDI Reports'
    this.EDI_SUPPORT = 'EDI Support'

    this.EDI_AS2_MAINTENANCE = 'AS2 Maintenance'

    this.EDI_REGISTRATION_ID = 'edi_registration_form'
    this.EDI_PARTNER_INFO_ID = 'edi_partner_info'
    this.EDI_REGISTRATION_STATUS_ID = 'edi_registration_status'
    this.EDI_DATA_VIEW_MFE_ID = 'edi_data_view_mfe'
    this.EDI_DATA_VIEW_ID = 'edi_data_view'
    this.EDI_TEST_DATA_GENERATION_ID = 'edi_test_data_generation'
    this.EDI_REPORTS_ID = 'edi_reports'
    this.EDI_SUPPORT_ID = 'edi_support'
    this.EDI_AS2_MAINTENANCE_ID = 'as2_maintenance'
    this.PARTNER_ALTERNATE_ID = 'Partner Alternate ID'
    this.FEX_AS2_MAINTENANCE = 'FEX AS2 Maintenance'
    this.FEX_AS2_MAINTENANCE_ID = 'fex_as2_maintenance'

    this.state = {
      selectedNavLink: '',
    }
    this.EdiDataViewNavLinkInternal = this.EdiDataViewNavLinkInternal.bind(this)
    this.EdiDataViewNavLinkExternal = this.EdiDataViewNavLinkExternal.bind(this)
    this.EdiTestDataGenerationNavLink =
      this.EdiTestDataGenerationNavLink.bind(this)
    this.EdiReportsNavLink = this.EdiReportsNavLink.bind(this)
    this.EdiSupportNavLink = this.EdiSupportNavLink.bind(this)
    this.EdiPartnerInfoNavLink = this.EdiPartnerInfoNavLink.bind(this)
    this.EdiRegistrationNavLink = this.EdiRegistrationNavLink.bind(this)
    this.EssSideNavHeader = this.EssSideNavHeader.bind(this)
    this.EdiRegistrationStatus = this.EdiRegistrationStatus.bind(this)
    this.EdiDataViewMFE = this.EdiDataViewMFE.bind(this)
    this.PartnerAlternateId = this.PartnerAlternateId.bind(this)
    this.FEXAS2Maintenance = this.FEXAS2Maintenance.bind(this)
  }

  componentDidMount() {
    if (window.location.pathname === '/registration/status') {
      this.setState({
        selectedNavLink: this.EDI_REGISTRATION_STATUS_ID,
      })
    } else if (window.location.pathname === '/registration') {
      this.setState({
        selectedNavLink: this.EDI_REGISTRATION_ID,
      })
    } else if (window.location.pathname === '/codelist-mnt') {
      this.setState({
        selectedNavLink: this.FEX_AS2_MAINTENANCE_ID,
      })
    } else if (window.location.pathname === '/alternateId') {
      this.setState({
        selectedNavLink: this.PARTNER_ALTERNATE_ID,
      })
    } else if (window.location.pathname === '/data-view') {
      this.setState({
        selectedNavLink: this.EDI_DATA_VIEW_MFE,
      })
    }
  }

  render() {
    // console.log('LeftNavigation props: ' + JSON.stringify(this.props.env))
    return (
      <SideNav position="left" id="ess_left_navigation">
        <SideNav.Navigation>
          <this.EssSideNavHeader />
          <this.EdiRegistrationNavLink />
          <AccessControl
            role={[
              this.props.env.helpDeskAdminRole,
              this.props.env.supportAdminRole,
            ]}
            env={this.props.env}
          >
            <this.EdiRegistrationStatus />
          </AccessControl>
          <AccessControl
            role={[
              this.props.env.helpDeskAdminRole,
              this.props.env.supportAdminRole,
              this.props.env.internalUserRole,
            ]}
            env={this.props.env}
          >
            <this.EdiPartnerInfoNavLink />
            <this.EdiDataViewNavLinkInternal />
          </AccessControl>
          <AccessControl
            role={[this.props.env.externalUserRole]}
            env={this.props.env}
          >
            <this.EdiPartnerInfoNavLink />
            <this.EdiDataViewNavLinkExternal />
          </AccessControl>
          <AccessControl
            role={[
              this.props.env.helpDeskAdminRole,
              this.props.env.supportAdminRole,
            ]}
            env={this.props.env}
          >
            <this.EdiTestDataGenerationNavLink />
          </AccessControl>
          <AccessControl
            role={[
              this.props.env.helpDeskAdminRole,
              this.props.env.supportAdminRole,
              this.props.env.internalUserRole,
            ]}
            env={this.props.env}
          >
            <this.EdiReportsNavLink />
          </AccessControl>
          <AccessControl
            role={[this.props.env.supportAdmin]}
            env={this.props.env}
          >
            <this.EdiSupportNavLink />
          </AccessControl>
          {/* <AccessControl
            role={[this.props.env.supportAdminRole]}
            env={this.props.env}
          >
            <this.EdiDataViewMFE />
          </AccessControl> */}
          <this.PartnerAlternateId />
          <AccessControl
            role={[
              this.props.env.helpDeskAdminRole,
              this.props.env.supportAdminRole,
            ]}
            env={this.props.env}
          >
            <this.FEXAS2Maintenance />
          </AccessControl>
          <TestUserLogin
            currentEnv={GlobalConstants.APPLICATION_ENVIRONMENT}
            enableTestLogin={this.props.env.enableTestLogins}
          />
        </SideNav.Navigation>
      </SideNav>
    )
  }

  EssSideNavHeader() {
    const ess_sidenav_header = {
      backgroundColor: '#C00',
      padding: '12px',
    }
    const ess_padding_left_5 = {
      paddingLeft: '5px',
    }
    return (
      <SideNav.Header className="ess_Layout_header" style={ess_sidenav_header}>
        <Grid.Item xs={12}>
          <Heading
            size={4}
            className="hc-mt-dense hc-mb-dense hc-clr-contrast-weak hc-hlh-expanded"
          >
            <TargetLogo color="white" size="inline" />
            <span style={ess_padding_left_5}>
              {GlobalConstants.APPLICATION_NAME}
            </span>
          </Heading>
        </Grid.Item>
      </SideNav.Header>
    )
  }

  EdiRegistrationNavLink() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_REGISTRATION_ID}
        onClick={(e) => {
          window.location.href = '/registration'
        }}
        selected={this.EDI_REGISTRATION_ID === this.state.selectedNavLink}
      >
        <AccessControl
          role={[this.props.env.externalUserRole]}
          env={this.props.env}
        >
          {this.EDI_REGISTRATION_USER}
        </AccessControl>
        <AccessControl
          role={[
            this.props.env.helpDeskAdminRole,
            this.props.env.supportAdminRole,
          ]}
          env={this.props.env}
        >
          {this.EDI_REGISTRATION_INTERNAL_USER}
        </AccessControl>
      </SideNav.NavigationItem>
    )
  }

  EdiPartnerInfoNavLink() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_PARTNER_INFO_ID}
        onClick={(e) => {
          window.location.href =
            this.props.env.legacy.legacyPssUrl +
            '/edipartner/searchPartnerInfo.do'
        }}
        selected={this.EDI_PARTNER_INFO_ID === this.state.selectedNavLink}
      >
        {this.EDI_PARTNER_INFO}
      </SideNav.NavigationItem>
    )
  }

  EdiDataViewNavLinkInternal() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_DATA_VIEW_ID}
        onClick={(e) => {
          window.location.href =
            this.props.env.legacy.legacyPssUrl + '/edidata/selectEdiData.do'
        }}
        selected={this.EDI_DATA_VIEW_ID === this.state.selectedNavLink}
      >
        {this.EDI_DATA_VIEW}
      </SideNav.NavigationItem>
    )
  }

  EdiDataViewNavLinkExternal() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_DATA_VIEW_ID}
        onClick={(e) => {
          window.location.href =
            this.props.env.legacy.legacyPssUrl + '/common/home/home.do'
        }}
        selected={this.EDI_DATA_VIEW_ID === this.state.selectedNavLink}
      >
        {this.EDI_DATA_VIEW}
      </SideNav.NavigationItem>
    )
  }

  EdiTestDataGenerationNavLink() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_TEST_DATA_GENERATION_ID}
        onClick={(e) => {
          window.location.href =
            this.props.env.legacy.legacyPssUrl +
            '/itt/inboundSelection.do?mode=createrequest'
        }}
        selected={
          this.EDI_TEST_DATA_GENERATION_ID === this.state.selectedNavLink
        }
      >
        {this.EDI_TEST_DATA_GENERATION}
      </SideNav.NavigationItem>
    )
  }

  EdiReportsNavLink() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_REPORTS_ID}
        onClick={(e) => {
          window.location.href =
            this.props.env.legacy.legacyPssUrl +
            '/edireports/reportSelection.do'
        }}
        selected={this.EDI_REPORTS_ID === this.state.selectedNavLink}
      >
        {this.EDI_REPORTS}
      </SideNav.NavigationItem>
    )
  }

  EdiSupportNavLink() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_SUPPORT_ID}
        onClick={(e) => {
          window.location.href =
            this.props.env.legacy.legacyPssUrl + '/dev/addDocument.do'
        }}
        selected={this.EDI_SUPPORT_ID === this.state.selectedNavLink}
      >
        {this.EDI_SUPPORT}
      </SideNav.NavigationItem>
    )
  }

  EdiRegistrationStatus() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_REGISTRATION_STATUS_ID}
        onClick={(e) => {
          window.location.href = '/registration/status'
        }}
        selected={
          this.EDI_REGISTRATION_STATUS_ID === this.state.selectedNavLink
        }
        to="/registration/status"
      >
        {this.EDI_REGISTRATION_STATUS}
      </SideNav.NavigationItem>
    )
  }

  FEXAS2Maintenance() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.FEX_AS2_MAINTENANCE_ID}
        onClick={(e) => {
          window.location.href = '/codelist-mnt'
        }}
        selected={this.FEX_AS2_MAINTENANCE_ID === this.state.selectedNavLink}
        to="/codelist-mnt"
      >
        {this.FEX_AS2_MAINTENANCE}
      </SideNav.NavigationItem>
    )
  }

  EdiDataViewMFE() {
    return (
      <SideNav.NavigationItem
        as={Anchor}
        id={this.EDI_DATA_VIEW_MFE_ID}
        onClick={(e) => {
          window.location.href = '/data-view'
        }}
        selected={this.EDI_DATA_VIEW_MFE_ID === this.state.selectedNavLink}
        to="/data-view"
      >
        {this.EDI_DATA_VIEW_MFE}
      </SideNav.NavigationItem>
    )
  }

  PartnerAlternateId() {
    if (window.location.href.includes('alternateId')) {
      return (
        <SideNav.NavigationItem
          id={this.PARTNER_ALTERNATE_ID}
          selected={this.PARTNER_ALTERNATE_ID === this.state.selectedNavLink}
        >
          {this.PARTNER_ALTERNATE_ID}
        </SideNav.NavigationItem>
      )
    } else {
      return <></>
    }
  }
}

export default LeftNavigation
