import React from 'react'
import { RegistrationError } from './RegistrationError'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import Logger from './Logger'
import { LOGGER_LEVEL_ERROR } from '../CommonConstants'

/*This is a generic Error Handling component. */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    })
  }
  render() {
    if (this.state.hasError) {
      var errorInfo = this.state.errorInfo.componentStack
      var errorInfoMasked = errorInfo.replace(/Auth/g, 'Axxx')
      Logger(
        'ErrorBoundary: ' +
          this.state.error.toString() +
          '.  Stacktrace: ' +
          errorInfoMasked,
        LOGGER_LEVEL_ERROR,
        window.location.href.toString()
      )
      return (
        <div>
          <Heading size={5}>
            <RegistrationError
              inError={true}
              message="There was an unexpected technical issue. Please try again
            momentarily. If you get this message again, please contact
            EDI.helpdesk@target.com for next steps."
            />
          </Heading>
        </div>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary
