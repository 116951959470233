import { usePromiseTracker } from 'react-promise-tracker'
import Loader from 'react-loader-spinner'

export const LoadingSpinnerComponent = (props) => {
  const { promiseInProgress } = usePromiseTracker()

  return (
    <div>
      {promiseInProgress === true ? (
        <div
          style={{
            display: 'flex',
            paddingTop: '0px',
            marginTop: '0px',
            justifyContent: 'center',
          }}
        >
          <Loader type="ThreeDots" color="#D0021B" height="100" width="100" />
        </div>
      ) : null}
    </div>
  )
}
