import { Layout } from '@enterprise-ui/canvas-ui-react'
// import MicroFrontend from '../../../MicroFrontend'
import AccessControl from '../security/AccessControl'
import { LoadingSpinnerComponent } from '../util/LoadingSpinnerComponent'
import ErrorBoundary from '../util/ErrorBoundary'
import { Route, Routes } from 'react-router-dom'
import { MicroFrontend as BaseMicroFrontend } from '@praxis/component-microfrontend'
import './Content.css'
import RequiredMessage from './RequiredMessage'
import AlternateIdDetails from '../../alternateId/AlternateIdDetails'

const Content = (props) => {
  //console.log('props in content...' + JSON.stringify(props.userInfo))
  //console.log('env Props in content: ' + JSON.stringify(props.env))

  // function BexDataView({ history }) {
  //   return (
  //     <div>
  //       <div className="home">
  //         <MicroFrontend
  //           history={history}
  //           host={props.env.bex.mfe.dataView}
  //           name="Bexdataview"
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <Layout.Body>
      <div id="ess_body" className="ess_body">
        <ErrorBoundary>
          <Routes>
            <Route
              path="/registration/*"
              element={
                <AccessControl
                  role={[
                    props.env.helpDeskAdminRole,
                    props.env.supportAdminRole,
                    props.env.externalUserRole,
                  ]}
                  env={props.env}
                >
                  <BaseMicroFrontend
                    src={props.env.bex.mfe.registration}
                    {...props}
                  />
                </AccessControl>
              }
            />
            <Route
              path="/codelist-mnt"
              element={
                <AccessControl
                  role={[
                    props.env.helpDeskAdminRole,
                    props.env.supportAdminRole,
                  ]}
                  env={props.env}
                >
                  <BaseMicroFrontend
                    src={props.env.bex.mfe.as2maintenance}
                    {...props}
                  />
                </AccessControl>
              }
            />
            <Route
              path="/"
              element={
                <div
                  style={{
                    paddingBottom: '70%',
                    fontSize: '18px',
                    fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                  }}
                >
                  Welcome to{' '}
                  <span style={{ fontWeight: '700' }}>
                    B2B Data Exchange (BEX)
                  </span>
                </div>
              }
            ></Route>
            <Route
              path="/alternateId"
              element={
                <AccessControl
                  role={[
                    props.env.helpDeskAdminRole,
                    props.env.supportAdminRole,
                    props.env.externalUserRole,
                  ]}
                  env={props.env}
                >
                  <AlternateIdDetails {...props} />
                </AccessControl>
              }
            />
            <Route
              path="/data-view"
              element={
                <AccessControl
                  role={[
                    props.env.helpDeskAdminRole,
                    props.env.supportAdminRole,
                    props.env.externalUserRole,
                  ]}
                  env={props.env}
                >
                  <BaseMicroFrontend
                    src={props.env.bex.mfe.dataView}
                    {...props}
                  />
                </AccessControl>
              }
            />
          </Routes>
          <LoadingSpinnerComponent />
          <RequiredMessage />
        </ErrorBoundary>
      </div>
    </Layout.Body>
  )
}

export default Content
