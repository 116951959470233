import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import ErrorBoundary from '../util/ErrorBoundary'

class Header extends React.Component {
  render() {
    return <this.Content />
  }

  Content() {
    return (
      <ErrorBoundary style={{ background: '#c00' }}>
        <Layout.Header
          style={{ background: '#C00', position: 'absolute', top: 0, left: 0 }}
        >
          <Grid.Container style={{ background: '#c00' }}>
            <Grid.Item xs={5} style={{ background: '#c00' }}>
              <Heading
                size={4}
                style={{ background: '#c00' }}
                className="hc-mt-dense hc-mb-dense hc-clr-contrast-weak hc-hlh-expanded"
              >
                {/* <TargetLogo size="inline" /> */}
              </Heading>
            </Grid.Item>
            <Grid.Item xs={4}>
              <Heading
                size={5}
                className="hc-mt-dense hc-mb-dense hc-clr-contrast-weak hc-hlh-expanded"
              >
                {/* <TargetLogo size="inline" /> */}
              </Heading>
            </Grid.Item>
            <Grid.Item xs={3}></Grid.Item>
          </Grid.Container>
        </Layout.Header>
      </ErrorBoundary>
    )
  }
}

export default Header
