import { useEffect, useState } from 'react'
import { Layout, GridItem } from '@enterprise-ui/canvas-ui-react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import './GlobalActions.css'
import authUtil, { clearLocalStorage } from '../util/authUtil'
import UserInfo from '../util/UserInfo'

const GlobalActions = (props) => {
  const [updateNow, setUpdateNow] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      const isSessionValid = authUtil(props.auth)
      if (!isSessionValid) {
        clearLocalStorage()
      } else {
        setUpdateNow(!updateNow)
      }
    }, 10000)
    return () => clearInterval(interval)
  })
  return (
    <Layout.GlobalActions>
      <Grid.Container justify="flex-end">
        <GridItem>
          <UserInfo id="global_user_info" {...props} />
        </GridItem>
      </Grid.Container>
    </Layout.GlobalActions>
  )
}

export default GlobalActions
