import React from 'react'
import { Form, Anchor, Tooltip, Divider } from '@enterprise-ui/canvas-ui-react'
import './TestUserLogin.css'

class TestUserLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [
        { label: ' ', value: ' ' },
        {
          label: 'External Air carrier - BAA17840',
          value: {
            userId: 'BAA17840',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Consolidator — BAA15688',
          value: {
            userId: 'BAA15688',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Import Consolidator — BAA15392',
          value: {
            userId: 'BAA15392',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Carrier user - BAA15743',
          value: {
            userId: 'BAA15743',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Deconsolidator - BAA13485',
          value: {
            userId: 'BAA13485',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Domestic Carrier - BAA15742',
          value: {
            userId: 'BAA15742',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Domestic Carrier - BAA15743',
          value: {
            userId: 'BAA15743',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Domestic Transportation Consol/Deconsol - BAC83410',
          value: {
            userId: 'BAC83410',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External DVS user - BAA16787',
          value: {
            userId: 'BAA16787',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Import Carrier - BAA20685',
          value: {
            userId: 'BAA20685',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Import Carrier Invoice - BAB20477',
          value: {
            userId: 'BAB20477',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Import Ocean Carrier - BAB20472',
          value: {
            userId: 'BAB20472',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Import Vendor - BAA17180',
          value: {
            userId: 'BAA17180',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Target Domestic user - BAA17177',
          value: {
            userId: 'BAA17177',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Target Domestic user - BAA17231',
          value: {
            userId: 'BAA17231',
            memberOf: ['APP-BEX'],
          },
        },
        {
          label: 'External Invalid VMM user - Z00000',
          value: {
            userId: 'Z00000',
            memberOf: ['APP-BEX'],
          },
        },
        //Internal user
        {
          label: 'Internal BEX USER - bexUser',
          value: {
            userId: 'bexUser',
            memberOf: ['APP-BEX-USERS'],
          },
        },
        {
          label: 'Internal Bex Helpdesk Admin - APP-BEX-HELPDESK-ADMIN',
          value: {
            userId: 'bexHelpDeskAdmin',
            memberOf: ['APP-BEX-HELPDESK-ADMIN-STG'],
          },
        },
        {
          label: 'Internal Bex Helpdesk Admin - APP-BEX-SUPPORT-ADMIN',
          value: {
            userId: 'bexSupportAdmin',
            memberOf: ['APP-BEX-SUPPORT-ADMIN-STG'],
          },
        },
      ],
      selectedUser: '',
    }
    this.removeTestUser = this.removeTestUser.bind(this)
  }

  render() {
    if (this.props.enableTestLogin === true) {
      return (
        <div style={{ paddingTop: '700Px' }}>
          <div style={{ padding: '20Px' }}>
            <Divider />
            <div>
              <div style={{ paddingTop: '25px', paddingBottom: '20px' }}>
                Now Using Test UserId: {localStorage.getItem('testLoginUser')}
                <br />
                User Roles:{' '}
                {localStorage.getItem('testLoginUserDetails')
                  ? JSON.parse(localStorage.getItem('testLoginUserDetails'))
                      .memberOf
                  : ''}
                <br />
                <Anchor
                  onClick={() => {
                    this.removeTestUser()
                    window.location.reload()
                  }}
                >
                  Remove Test User
                </Anchor>
              </div>

              <Form.Field
                type="select"
                id="testUserList"
                label="User ID"
                value={localStorage.getItem('testLoginUser')}
                onUpdate={(id, value) => {
                  localStorage.setItem('testLoginUser', value.userId)
                  localStorage.setItem(
                    'testLoginUserDetails',
                    JSON.stringify(value)
                  )
                  window.location.href = '/'
                }}
                required
                options={this.state.users}
                rightContent={
                  <Tooltip
                    id="testLoginUserToolTip"
                    location="top-left"
                    className="C-toolpit-custom-width"
                    content="This option is available in lower environments 
                  only for testing purpose."
                  >
                    <Anchor>What is this?</Anchor>
                  </Tooltip>
                }
              />
            </div>
          </div>
        </div>
      )
    } else {
      this.removeTestUser()
      return <React.Fragment></React.Fragment>
    }
  }

  removeTestUser() {
    localStorage.removeItem('testLoginUser')
    localStorage.removeItem('testLoginUserDetails')
  }
}
export default TestUserLogin
