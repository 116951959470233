import Home from './components/Home'
import { EnvProvider } from '@praxis/component-runtime-env'
import { AuthProvider } from '@praxis/component-auth'
import 'typeface-roboto'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import commonConfig from './commonConfig'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

export const App = () => {
  return (
    <ToastProvider location="bottom">
      <EnvProvider
        commonConfig={commonConfig}
        configPath={
          process.env.NODE_ENV === 'development' ||
          process.env.NODE_ENV === 'dev'
            ? '/config.json'
            : '/app-environment'
        }
      >
        {({ env }) => {
          //console.log('env: ' + process.env.REACT_APP_ENV)
          return (
            <AuthProvider
              {...env.auth}
              shouldSendHeaders={() => true}
              loginRedirect={`${window.location.origin}`}
            >
              <Home id="root" env={env} />
            </AuthProvider>
          )
        }}
      </EnvProvider>
    </ToastProvider>
  )
}

export default App
