import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import EssHeader from './global/layout/Header'
import ESSBody from './global/layout/Content'
import ESSGlobalActions from './global/layout/GlobalActions'
import LeftNavigation from './global/layout/LeftNavigation'
import Footer from './global/layout/Footer'
import Logger from './global/util/Logger'
import { LOGGER_LEVEL_INFO } from './global/CommonConstants'
import { useAuth } from '@praxis/component-auth'
import UserRoles from './global/security/UserRoles'
import './Home.css'
import { ProtectedElement } from '@praxis/component-auth'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

const Home = (props) => {
  const auth = useAuth()
  //The Route is protected - Check App.js to change anything related to Routes or Authentication. The User should already be logged for this component to be rendered due to protection of the route
  if (auth) {
    if (auth.isAuthenticated()) {
      const userInfo = auth.session.userInfo
      var userRoles = UserRoles(props)
      //console.log('current user roles: ' + JSON.stringify(userRoles))
      Logger(
        'Form is rendering for user: ' + auth.session.userInfo.lanId,
        LOGGER_LEVEL_INFO,
        window.location.href.toString()
      )
      return (
        <React.Fragment>
          <Layout fullwidth="true" darkMode="false">
            <EssHeader />
            <Router>
              <ESSGlobalActions auth={auth} />
              <LeftNavigation env={props.env} />
              <ESSBody
                className="ess-body"
                userInfo={userInfo}
                userRoles={userRoles}
                env={props.env}
              />
            </Router>

            <Footer env={props.env} />
          </Layout>
        </React.Fragment>
      )
    } else {
      return (
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <ProtectedElement>
                  <div>User Not Logged</div>
                </ProtectedElement>
              }
            />
          </Routes>
        </Router>
      )
    }
  } else {
    return <React.Fragment />
  }
}

export default Home
