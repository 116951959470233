import React, { useState, useEffect } from 'react'
import axios from 'axios'
// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
import {
  Grid,
  ToastProvider,
  Divider,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DuplicateIcon } from '@enterprise-ui/icons'
import AddAlternateId from './AddAlternateId'
import { RegistrationError } from '../global/util/RegistrationError'
const AlternateIdDetails = (props) => {
  const [addAlternateIds, setAddAlternateIds] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [param, setParam] = useState({
    partnerName: '',
    partnerId: '',
    qualifier: '',
    operatingCompany: 0,
  })

  const fun = async (p) => {
    const PARTNER_INFO_ENDPOINT =
      props.env?.partnerInfo.url + '/v1/alternatePartnerDetails'
    try {
      const response = await axios.get(PARTNER_INFO_ENDPOINT, {
        params: {
          ediPartnerId: p.partnerId,
          ediPartnerQualifier: p.qualifier,
          operatingCompany: p.operatingParam,
        },
      })
      if (response && response.data && response.data.length) {
        console.log(response)
        setAddAlternateIds(response.data)
      }
    } catch (err) {
      makeToast({
        autoHideDuration: 5000,
        type: 'error',
        heading: 'Could Not Load Data',
        message: 'Server might not be up and running',
      })
    }
  }

  const validateParam = (urlParams) => {
    if (
      urlParams.get('partnerName') &&
      urlParams.get('partnerId') &&
      urlParams.get('qualifier') &&
      urlParams.get('operatingCompany')
    ) {
      const operatingCompany = urlParams.get('operatingCompany').toUpperCase()

      let operatingParam
      switch (operatingCompany) {
        case 'TARGET STORES':
          operatingParam = 4
          break
        case 'TARGET.COM':
          operatingParam = 5
          break
        case 'TGSL':
          operatingParam = 95
          break
        default:
          console.log(urlParams.get('operatingCompany').toUpperCase())
      }
      return {
        partnerName: urlParams.get('partnerName').trim(),
        partnerId: urlParams.get('partnerId').trim(),
        qualifier: urlParams.get('qualifier').trim(),
        operatingCompany: urlParams.get('operatingCompany').trim(),
        operatingParam: operatingParam,
      }
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const p = validateParam(urlParams)
    setParam({
      partnerName: p?.partnerName,
      partnerId: p?.partnerId,
      qualifier: p?.qualifier,
      operatingCompany: p?.operatingCompany,
      operatingParam: p?.operatingParam,
    })
    fun(p)
    setLoading(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const makeToast = ToastProvider.useToaster()

  const addData = async (data) => {
    console.log(data)
    const PARTNER_INFO_ENDPOINT_ADD =
      props.env?.partnerInfo.url + '/v1/alternatePartnerDetails'
    try {
      if (
        data.documentNumber &&
        data.alternateEdiPartnerId &&
        data.alternateEdiPartnerQualifier
      ) {
        const response = await axios.post(PARTNER_INFO_ENDPOINT_ADD, {
          operatingCompany: param.operatingParam,
          ediPartnerId: param.partnerId,
          ediPartnerQualifier: param.qualifier,
          documentNumber: data.documentNumber,
          alternateEdiPartnerId: data.alternateEdiPartnerId,
          alternateEdiPartnerQualifier: data.alternateEdiPartnerQualifier,
          createUser: props.userInfo.lanId,
          updateUser: props.userInfo.lanId,
        })
        if (response && response.data) {
          makeToast({
            autoHideDuration: 5000,
            type: 'success',
            heading: 'Data Added',
            message: 'The data has been successfully added.',
          })

          fun(param)
          console.log(response.data)
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response)
        makeToast({
          autoHideDuration: 5000,
          type: 'error',
          heading: 'Could Not Add Data',
          message: err?.response?.data?.data,
        })
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 422) {
            setError(true)
          }
        }
      } else {
        makeToast({
          autoHideDuration: 5000,
          type: 'error',
          heading: 'Could Not Add Data',
        })
      }
    }
  }

  const addEditableData = () => {
    setAddAlternateIds([...addAlternateIds, {}])
  }

  const deleteEditableData = async (i) => {
    var findIndex = addAlternateIds.findIndex(
      (item) => item?.documentNumber === i?.documentNumber
    )
    const PARTNER_INFO_ENDPOINT_DELETE =
      props.env?.partnerInfo.url + `/v1/alternatePartnerDetails`
    try {
      const response = await axios.delete(PARTNER_INFO_ENDPOINT_DELETE, {
        params: {
          ediPartnerId: i.ediPartnerId,
          ediPartnerQualifier: i.ediPartnerQualifier,
          operatingCompany: i.operatingCompany,
          documentNumber: i.documentNumber,
        },
      })
      if (response?.data?.success === true) {
        var index = addAlternateIds?.filter(
          (item, index) => index !== findIndex
        )
        setAddAlternateIds(index)
        makeToast({
          autoHideDuration: 5000,
          type: 'success',
          heading: 'Data Deleted',
          message: response.data.message,
        })
      }
    } catch (err) {
      console.log(err)
      makeToast({
        autoHideDuration: 5000,
        type: 'error',
        heading: 'Could Not Delete Data',
        message: err?.response?.data?.data,
      })
    }
  }

  return error ? (
    <div>
      <Heading size={5}>
        <RegistrationError
          inError={true}
          message="There was an unexpected technical issue. Please try again
            momentarily. If you get this message again, please contact
            EDI.helpdesk@target.com for next steps."
        />
      </Heading>
    </div>
  ) : (
    <React.Fragment>
      <Grid.Container>
        <Grid.Item xs={12}></Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Partner Name</h3>
        </Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Partner ID</h3>
        </Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Qualifer</h3>
        </Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <div className="hc-pa-dense hc-bg-grey08">{param.partnerName}</div>
        </Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <div className="hc-pa-dense hc-bg-grey08">{param.partnerId}</div>
        </Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <div className="hc-pa-dense hc-bg-grey08">{param.qualifier}</div>
        </Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Operating Company</h3>
        </Grid.Item>
        <Grid.Item xs={8}></Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={3}>
          <div className="hc-pa-dense hc-bg-grey08">
            {param.operatingCompany}
          </div>
        </Grid.Item>
        <Grid.Item xs={8}></Grid.Item>

        <Grid.Item xs={12}></Grid.Item>
        <Divider width={1} />
        <Grid.Item xs={12}></Grid.Item>
        <Grid.Item xs={1}></Grid.Item>
        <Grid.Item xs={1}>
          {loading === true ? (
            addAlternateIds?.length < 2 && (
              <EnterpriseIcon
                icon={DuplicateIcon}
                size="xl"
                onClick={() => addEditableData()}
              />
            )
          ) : (
            <></>
          )}
        </Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Document Type</h3>
        </Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Alternate ID</h3>
        </Grid.Item>
        <Grid.Item xs={3}>
          <h3 className="C-Heading --size-h4">Qualifier</h3>
        </Grid.Item>

        {addAlternateIds.map((index) => (
          <AddAlternateId
            key={index}
            addAlternateIds={index}
            deleteEditableData={() => {
              console.log(index)
              deleteEditableData(index)
            }}
            addData={addData}
          />
        ))}
      </Grid.Container>
    </React.Fragment>
  )
}

export default AlternateIdDetails
