import React, { useState } from 'react'
import { Grid, Chip, Form, Modal, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { TrashIcon, PlusIcon } from '@enterprise-ui/icons'

const AddAlternateId = (props) => {
  const [displayText, setDisplayText] = useState('Select Document Type')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState('')
  const [qualifier, setQualifier] = useState('')
  const [isDocumentTypeInValid, setIsDocumentTypeInValid] = useState(false)
  const [isAlternateIdInvalid, setIsAlternateIdInvalid] = useState(false)
  const [isQualifierInvalid, setIsQualifierInvalid] = useState(false)

  const addData = () => {
    const data = {
      documentNumber: displayText,
      alternateEdiPartnerId: id,
      alternateEdiPartnerQualifier: qualifier,
    }
    props.addData(data)
  }

  const deleteEditableData = () => {
    props.deleteEditableData()
  }
  return (
    <React.Fragment>
      <Grid.Container>
        <Grid.Item xs={2}></Grid.Item>
        <Grid.Item xs={3}>
          {!props.addAlternateIds?.alternateEdiPartnerId ? (
            <Form.Field
              type="select"
              id="document_type"
              error={isDocumentTypeInValid}
              onUpdate={(id, value) => {
                console.log('selected status: ' + value)
                setDisplayText(value)
                setIsDocumentTypeInValid(false)
              }}
              options={[
                {
                  disabled: false,
                  label: '820',
                  value: '820',
                },
                {
                  disabled: false,
                  label: '852',
                  value: '852',
                },
              ]}
              label="Document Type"
              errorText="Document Type is required"
              required
            >
              {props.addAlternateIds && props.addAlternateIds.documentNumber
                ? props.addAlternateIds.documentNumber
                : displayText}
            </Form.Field>
          ) : (
            <Chip>
              {props.addAlternateIds && props.addAlternateIds.documentNumber
                ? props.addAlternateIds.documentNumber
                : displayText}
            </Chip>
          )}
        </Grid.Item>
        <Grid.Item xs={3}>
          {!props.addAlternateIds?.alternateEdiPartnerId ? (
            <Form.Field
              id="alternate_partner_id"
              value={id}
              error={isAlternateIdInvalid}
              onChange={(altId) => {
                setId(altId.target.value)
                setIsAlternateIdInvalid(false)
              }}
              label="Alternate ID"
              errorText="Alternate ID is required"
              required
            />
          ) : (
            <Form.Field
              value={
                props.addAlternateIds &&
                props.addAlternateIds.alternateEdiPartnerId
                  ? props.addAlternateIds.alternateEdiPartnerId
                  : ''
              }
              disabled="true"
            />
          )}
        </Grid.Item>
        <Grid.Item xs={3}>
          {!props.addAlternateIds?.alternateEdiPartnerId ? (
            <Form.Field
              id="qualifier"
              error={isQualifierInvalid}
              value={qualifier}
              onChange={(qual) => {
                setQualifier(qual.target.value)
                setIsQualifierInvalid(false)
              }}
              label="Qualifier"
              errorText="Qualifier is required"
              required
            />
          ) : (
            <Form.Field
              value={
                props.addAlternateIds &&
                props.addAlternateIds.alternateEdiPartnerQualifier
                  ? props.addAlternateIds.alternateEdiPartnerQualifier
                  : ''
              }
              disabled="true"
            />
          )}
        </Grid.Item>
        <Grid.Item xs={1}>
          {props.addAlternateIds?.alternateEdiPartnerId === undefined ? (
            <EnterpriseIcon
              style={{ margin: 15 }}
              icon={PlusIcon}
              size="xl"
              onClick={() => {
                if (displayText === 'Select Document Type') {
                  setIsDocumentTypeInValid(true)
                  //alert('Fill every details')
                } else if (!id) {
                  setIsAlternateIdInvalid(true)
                } else if (!qualifier) {
                  setIsQualifierInvalid(true)
                } else {
                  addData()
                }
              }}
            />
          ) : (
            <EnterpriseIcon
              icon={TrashIcon}
              size="xl"
              onClick={() => setIsModalVisible(true)}
            />
          )}
        </Grid.Item>
        <Grid.Item xs={12}></Grid.Item>
      </Grid.Container>
      <Modal
        headingText="Do you really want to delete the row?"
        onRefuse={() => setIsModalVisible(false)}
        isVisible={isModalVisible}
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <p>Click yes to delete</p>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  deleteEditableData()
                  setIsModalVisible(false)
                }}
              >
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button type="secondary" onClick={() => setIsModalVisible(false)}>
                No
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AddAlternateId
