import React from 'react'
import { useAuth } from '@praxis/component-auth'
import { Dropdown, Grid, Avatar } from '@enterprise-ui/canvas-ui-react'
import * as GlobalConstants from '../HeaderConstants'

const UserInfo = (props) => {
  const auth = useAuth()
  const firstName = auth?.session?.userInfo?.firstName
  const lastName = auth?.session?.userInfo?.lastName
  const company = auth?.session?.identity?.company
  return (
    <React.Fragment>
      <Dropdown size="dense" location="bottom-left">
        <Grid.Container>
          <Grid.Item style={{ paddingBottom: '0px' }}>
            <div style={{ display: 'inline', float: 'left' }}>
              <div style={{ display: 'inline' }}>
                <Avatar aria-label="Salvador Dalí avatar" color="lime">
                  {firstName?.charAt(0) + lastName?.charAt(0)}
                </Avatar>
              </div>
              <div
                style={{
                  display: 'inline',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                <div>{firstName + ' ' + lastName}</div>
                <div id="user_company_name" style={{ fontSize: '12px' }}>
                  {company}
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'inline',
                float: 'right',
                paddingLeft: '10px',
              }}
            >
              <span aria-label="triangle">▼</span>
            </div>
          </Grid.Item>
        </Grid.Container>
        <Dropdown.Menu>
          <Dropdown.MenuItem>
            {GlobalConstants.HEADER_MENU_ITEM_PROFILE}
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={props.auth.logout}>
            {GlobalConstants.HEADER_MENU_ITEM_LOGOUT}
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}

export default UserInfo
