import React from 'react'
// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
import { Layout, Grid, Anchor } from '@enterprise-ui/canvas-ui-react'
import './Footer.css'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const COPYRIGHT_LINK_LABEL = this.props.env.pol.copyrightLabel
    const CONTACTUS_LINK_LABEL = 'Contact Us'
    const SUPPORT_LINK_LABEL = 'Request Support'
    const COPYRIGHT_LINK = this.props.env.pol.copyrightLink
    const CONTACTUS_LINK = '/page/whotocontact'
    const SUPPORT_LINK = '/page/servicenow/support'

    return (
      <Layout.Footer id="ess_footer">
        <Grid.Container
          justify="space-between"
          className="ess_footer_div_content"
        >
          <Grid.Item xs={3}>
            <div className="hc-pa-dense">
              <Anchor
                target="_blank"
                className="ess_footer_link ess_footer_link_padding"
                href={this.props.env.legacy.polUrl + COPYRIGHT_LINK}
              >
                {COPYRIGHT_LINK_LABEL}
              </Anchor>
            </div>
          </Grid.Item>
          <Grid.Item xs={8}>
            <div className="hc-pa-dense">
              <p className="ess_div_content_right">
                <Anchor
                  className="ess_footer_link"
                  href={this.props.env.legacy.polUrl + CONTACTUS_LINK}
                >
                  {CONTACTUS_LINK_LABEL}
                </Anchor>
                <span className="ess_footer_pipe">|</span>
                <Anchor
                  className="ess_footer_link"
                  href={this.props.env.legacy.polUrl + SUPPORT_LINK}
                >
                  {SUPPORT_LINK_LABEL}
                </Anchor>
              </p>
            </div>
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    )
  }
}

export default Footer
